<template>
  <div>
    <div class="comp-login">
      <div class="login-out">
        <div class="close-box" @click="SignInQX">
          <img
            style="width: 100%"
            src="@/assets/images/mobile/loginpopupgroup/icon17-new.png"
            alt="" />
        </div>
        <!-- <div class="login-title">
        <img style="width: 100%" src="@/assets/images/mobile/loginpopupgroup/icon15.png" alt="" />
      </div> -->
        <div class="reg-header">
          <div class="title">登录TMSKINS</div>
        </div>
        <div class="DZ-box-mobile">
          <div class="active-login">
            <span :class="{ 'is-pwd-login': PWCode }" @click="PWCode = true"
              >密码登录</span
            >
            <div class="line"></div>
            <span :class="{ 'is-pwd-login': !PWCode }" @click="PWCode = false"
              >验证码登录</span
            >
          </div>
          <el-form
            :model="SignInForm"
            status-icon
            ref="SignInForm"
            label-width="0px"
            class="demo-ruleForm">
            <el-form-item prop="mobile" label="">
              <div class="input-wrap">
                <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon2.png" alt="" class="phone-icon" /> -->
                <el-input
                  v-model="SignInForm.mobile"
                  placeholder="输入手机号"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="PWCode" prop="password" label="">
              <div class="input-wrap">
                <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
                <el-input
                  type="password"
                  v-model="SignInForm.password"
                  placeholder="输入密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-else label="" class="YZM-box" prop="verify">
              <div class="input-wrap code">
                <!-- <img src="@/assets/images/mobile/loginpopupgroup/icon12.png" alt="" class="phone-icon" /> -->
                <el-input
                  v-model="SignInForm.verify"
                  placeholder="输入验证码"></el-input>
              </div>
              <div class="YZM" @click="GetYanZhengMa(2)">
                {{ !VerificationShow ? "发送" : "已发送" }}
                <van-count-down
                  style="color: #bfbfed; font-size: 0.1rem"
                  v-show="VerificationShow"
                  :time="time"
                  class="CLR"
                  format="ss"
                  :auto-start="false"
                  @finish="finish"
                  ref="countDown" />
              </div>
            </el-form-item>
            <div class="args">
              <div class="args-item" style="margin-bottom: 0.05rem">
                <img
                  src="@/assets/images/mobile/loginpopupgroup/icon8-new.png"
                  v-show="!argsStatus1"
                  alt=""
                  @click="argsStatus1 = true" />
                <img
                  src="@/assets/images/mobile/loginpopupgroup/icon3-new.png"
                  v-show="argsStatus1"
                  alt=""
                  @click="argsStatus1 = false" />
                我已阅读并同意<span
                  @click="
                    $emit('closePopup');
                    $router.push('/Datapage?id=2');
                  "
                  >《用户协议》</span
                >和<span
                  @click="
                    $emit('closePopup');
                    $router.push('/Datapage?id=3');
                  "
                  >《隐私政策》</span
                >
              </div>
              <div class="args-item">
                <img
                  src="@/assets/images/mobile/loginpopupgroup/icon8-new.png"
                  v-show="!argsStatus2"
                  alt=""
                  @click="argsStatus2 = true" />
                <img
                  src="@/assets/images/mobile/loginpopupgroup/icon3-new.png"
                  v-show="argsStatus2"
                  alt=""
                  @click="argsStatus2 = false" />
                确认已满18岁，盲盒娱乐理性消费，请勿上头
              </div>
            </div>
            <el-form-item>
              <div class="btn-box">
                <div class="btn" @click="PostSignIn">登录</div>
              </div>
              <div class="btn-box regtext" style="margin-top: 0.12rem">
                没有账号？<span style="color: #bfbfed" @click="regclick()"
                  >点击注册</span
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Register,
  PostSendSms,
  SignIn,
  PersonalInfo,
  Arena_Box_List,
  Info,
} from "@/network/api.js";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      PWCode: true,
      argsStatus1: false,
      argsStatus2: false,
      VerificationShow: false,
      time: 60 * 1000,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },
      SignInForm: {
        mobile: "",
        password: "",
        verify: "",
      },
      rsgPopUpShow: false,
    };
  },

  methods: {
    ...mapActions({
      MyInfo: "Refresh",
    }),
    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.closeFun();
      //   this.GetSignInShow(false);
    },
    //清空登录表单
    EmptySignInForm() {
      // this.SignInForm.email = "";
      this.SignInForm.pass = "";
    },
    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
        this.GetIndividualsShow(true);
      });
    },
    regclick() {
      this.$emit("regshow", (this.rsgPopUpShow = true));
    },
    closeFun() {
      this.$emit("closePopup");
    },
    //登录操作
    PostSignIn() {
      if (!this.argsStatus1 || !this.argsStatus2) {
        this.$message.error(
          "请确认已满18周岁，并同意《用户协议》和《隐私条款》!"
        );
        return;
      }
      if (this.PWCode) {
        this.SignInForm.verify = "";
      }
      SignIn(
        this.SignInForm.mobile,
        this.SignInForm.password,
        this.SignInForm.verify
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem(
            "token",
            "Bearer" + " " + res.data.data.access_token
          );
          // if (this.checked == true) {
          //   localStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          //   sessionStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          // } else {
          //   sessionStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          // }
          this.GetSignInShow(false);
          this.closeFun();
          this.GetPersonalInfo();
          this.EmptySignInForm();
          this.MyInfo();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },
    //发送手机号验证码
    GetYanZhengMa(num) {
      if (this.RegisterForm.mobile || this.SignInForm.mobile) {
        this.VerificationShow = true;
        this.$refs.countDown.start();
        PostSendSms(
          this.RegisterForm.mobile || this.SignInForm.mobile,
          num
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs.countDown.reset();
    },
  },
};
</script>

<style lang="scss">
.comp-login {
  position: relative;
  .login-out {
    // display: flex;
    width: 100%;
    background: url(../../assets/images/mobile/loginpopupgroup/icon9-new2.png);
    background-size: 100% 100%;
    position: relative;
    padding: 0.1rem 0.1rem 0.12rem 0.22rem;
    box-sizing: border-box;

    .close-box {
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
      width: 0.12rem;
      height: 0.12rem;
    }
    .reg-header {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      color: #dfdbff;
      margin-bottom: 0.2rem;
    }
    .login-title {
      position: absolute;
      top: -0.43rem;
      left: 10%;
      width: 2.5rem;
    }
    .login-title2 {
      position: absolute;
      top: -0.4rem;
      left: 40%;
      width: 1.7rem;
    }
    .login-title3 {
      position: absolute;
      top: -0.4rem;
      left: 40%;
      width: 0.4rem;
    }
    .DZ-box-mobile {
      color: #fff;
      width: 100%;
      font-size: 0.14rem;
      padding: 0 0.66rem;
      .el-form-item__content {
        line-height: unset;
      }
      .active-login {
        display: flex;
        width: 100%;
        height: 0.28rem;
        margin-bottom: 0.18rem;
        align-items: center;
        .line {
          height: 0.1rem;
          width: 0.01rem;
          background: #313157;
        }
        span {
          width: 50%;
          height: 100%;
          color: #909090;
          font-size: 0.1rem;
          font-weight: 400;
          text-align: center;
          line-height: 0.28rem;
        }
        .is-pwd-login {
          color: #fff;
        }
      }
      .line-img {
        width: 100%;
        margin-bottom: 0.26rem;
      }
      .demo-ruleForm {
        .el-form-item {
          margin-bottom: 0.1rem;
        }
        .input-wrap {
          position: relative;
          width: 100%;
          display: flex;
          align-items: center;
          background: url(../../assets/images/mobile/loginpopupgroup/icon14-new.png)
            no-repeat;
          background-size: 100% 100%;
          .phone-icon {
            width: 0.18rem;
          }
          .el-input__inner {
            border: none;
            background-color: unset;
            color: #eee;
            font-size: 0.1rem;
            height: 0.23rem;
            line-height: 0.23rem;
          }
          .el-input__inner::-webkit-input-placeholder {
            color: rgba(191, 191, 237, 0.5);
          }
        }
      }

      .YZM-box {
        position: relative;
        width: 100%;
        .YZM {
          padding: 0rem 0.16rem;
          border-left: 0.01rem solid #32325d;
          position: absolute;
          height: 0.13rem;
          right: 0rem;
          top: 0.05rem;
          color: #bfbfed;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.1rem;
        }
      }

      .args {
        width: 100%;
        margin-bottom: 0.25rem;
        .args-item {
          display: flex;
          align-items: center;
          color: #bfbfed;
          font-size: 0.1rem;
          white-space: nowrap;
          img {
            width: 0.1rem;
            flex-shrink: 0;
            margin-right: 0.06rem;
          }
        }
      }

      .btn-box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        // margin-top: 0.16rem;

        .btn {
          width: 0.9rem;
          height: 0.26rem;
          background: url(../../assets/images/mobile/loginpopupgroup/icon10-new.png)
            no-repeat;
          background-size: 100% 100%;
          color: #d1d1d1;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0.14rem;
        }
      }
      .regtext {
        font-weight: 300;
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
}
</style>
