<template>
  <div class="comp-cprice">
    <div :style="`font-size:${size}rem`" class="price">
      <img
        :style="`width: ${size}rem; margin-right: 0.03rem`"
        src="@/assets/images/public/fire.png"
        alt=""
      />
      {{ price || 0 }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: [String, Number],
      default: 0,
    },
    size: {
      type: [String, Number],
      default: 0.12,
    },
  },
};
</script>

<style lang="scss">
.comp-cprice {
  display: flex;
  align-items: center;
  justify-content: center;

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(236, 177, 5);
    font-weight: 600;
  }
}
</style>