<template>
  <div>
    <div class="comp-m-header-m">
      <div @click="leftIconOnClick" class="left-icon">
        <!-- <img style="width: 0.3rem" src="../../assets/images/mobile/m-home2.png" alt="" srcset="" /> -->
      </div>
      <div class="center-logo" @click="$router.push('/home')">
        <img
          v-if="global_info.logo"
          style="width: 0.98rem; height: 0.24rem; display: block"
          :src="global_info.logo"
          alt=""
          srcset=""
        />
        <!-- <img
          style="width: 0.98rem; height: 0.24rem; display: block"
          src="../../assets/images/mobile/m-home-logo2.png"
          alt=""
          srcset=""
        /> -->
      </div>
      <div class="right-icon">
        <div v-if="!user.id" class="login-btn" @click="loginPopUpShow = true">
          <span>登录</span>
          <span>/</span>
          <span>注册</span>
        </div>
        <!-- <div v-if="!user.id" class="login-btn" >
          <span @click="loginPopUpShow = true">登录</span>
          <span>/</span>
          <span @click="rsgPopUpShow = true">注册</span>
        </div> -->
        <div v-else style="display: flex">
          <div class="beanbox">
            <Cprice
              class="money"
              :price="
                Math.round((Number(user.bean) + Number(user.integral)) * 100) /
                100
              "
            />
            <!-- <Cint class="money" :price="user.integral" /> -->
            <div class="price2">
              <img src="@/assets/images/about/user/zidan.png" alt="" />
              {{ user.ammunition_energy }}
            </div>
          </div>
          <img
            @click="gouser"
            style="
              width: 0.28rem;
              height: 0.28rem;
              border-radius: 50%;
              overflow: hidden;
            "
            :src="user.avatar"
            alt=""
            srcset=""
          />
        </div>
      </div>
      <!-- 左侧侧边栏弹窗 -->
      <!-- <van-overlay :lock-scroll="false" :show="leftNavShow">
        <transition name="fade">
          <div v-show="leftNavShow" class="left-box">
            <div @click="leftNavShow = !leftNavShow" class="close-btn">
              <img style="width: 0.2rem; height: 0.2rem" src="../../assets/images/mobile/m-home-close.png" alt="" srcset="" />
            </div>
            <div @click="meanItemOnClick(item)" v-for="item in meanList" :key="item.id" class="left-box-item" :class="[{ 'active-item': activeId === item.id }]">
              <div class="left-img">
                <img v-if="item.icon" style="width:100%;height100%;" :src="require(`../../assets/images/mobile/leftpopupgroup/${activeId === item.id ? item.aicon : item.icon
                  }.png`)
                  " alt="" />
              </div>
              <div class="right-text">
                {{ item.text }}
              </div>
              <div v-show="activeId === item.id" class="child-box">
                <div @click.stop="meanItemOnClick(vItem)" v-for="vItem in item.children" :key="vItem.id" class="left-box-item-child">
                  <div class="child-left-img">
                    <img v-if="vItem.icon" style="width: 94%" :src="require(`../../assets/images/mobile/leftpopupgroup/${vItem.icon}.png`)
                      " alt="" />
                  </div>
                  <div class="child-right-text">{{ vItem.text }}</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </van-overlay> -->
      <!-- 登录弹窗 -->
      <van-overlay :lock-scroll="false" :show="loginPopUpShow">
        <div class="login-popup">
          <LoginPopup
            @closePopup="loginPopUpShow = false"
            @regshow="regclick"
          />
        </div>
      </van-overlay>
      <!-- 注册弹窗 -->
      <van-overlay :lock-scroll="false" :show="rsgPopUpShow">
        <div class="reg-popup">
          <RegPopup @closePopup="rsgPopUpShow = false" @logshow="logclick" />
        </div>
      </van-overlay>
    </div>
    <div style="height: 0.44rem; width: 100%"></div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import LoginPopup from "@/components/common/LoginPopup";
import RegPopup from "@/components/common/RegPopup";
import { Info} from "@/network/api.js";
export default {
  components: {
    LoginPopup,
    RegPopup,
  },
  data() {
    return {
      leftNavShow: false,
      loginPopUpShow: false,
      rsgPopUpShow: false,
      activeId: "",
    };
  },
  computed: {
    ...mapState(["user", "global_info"]),
    meanList() {
      return [
        {
          id: 1,
          icon: "icon1",
          aicon: "a-icon1",
          text: "首页",
          path: "/Home",
          children: [],
        },
        // {
        //   id: 21,
        //   text: "经典盲盒",
        //   path: "/classicsBox",
        //   icon: "openbox",
        // },
        // {
        //   id: 22,
        //   text: "幸运饰品",
        //   path: "/Lucky",
        //   icon: "lucky",
        // },
        {
          id: 23,
          text: "虚拟对战",
          path: "/Battle",
          icon: "battle",
        },
        {
          id: 24,
          text: "ROLL房",
          path: "/Roll",
          icon: "rollicon",
        },
        // {
        //   id: 2,
        //   icon: "icon2",
        //   aicon: "a-icon2",
        //   text: "游戏中心",
        //   children: [
        //     {
        //       id: 21,
        //       text: "经典盲盒",
        //       path: "/classicsBox",
        //       icon: "openbox",
        //     },
        //     {
        //       id: 22,
        //       text: "幸运饰品",
        //       path: "/Lucky",
        //       icon: "lucky",
        //     },
        //     {
        //       id: 23,
        //       text: "虚拟对战",
        //       path: "/Battle",
        //       icon: "battle",
        //     },
        //     {
        //       id: 24,
        //       text: "ROLL房",
        //       path: "/Roll",
        //       icon: "rollicon",
        //     },
        //   ],
        // },
        {
          id: 3,
          icon: "icon3",
          aicon: "a-icon3",
          text: "商城",
          path: "/Mall",
          children: [],
        },
        {
          id: 4,
          icon: "icon4",
          aicon: "a-icon4",
          text: "分享",
          path: "/shareReward",
          children: [],
        },
        {
          id: 5,
          icon: "icon6",
          aicon: "a-icon6",
          text: "充值",
          path: "/BagRecharge",
          children: [],
        },
        {
          id: 6,
          icon: "icon5",
          aicon: "a-icon5",
          text: "个人中心",
          path: "/About",
          children: [],
        },
      ];
    },
  },
  created() {
    this.GetInfo();
  },
  methods: {
     ...mapMutations([
      "setGlobalInfo",
    ]),
    GetInfo() {
      Info().then((res) => {
        this.setGlobalInfo(res?.data?.data || {});
      });
    },
    gouser() {
      this.$router.push("/About");
      sessionStorage.setItem("activeIndex", 4);
    },
    leftIconOnClick() {
      this.activeId = 2;
      this.leftNavShow = true;
    },
    regclick(v) {
      this.rsgPopUpShow = v;
      this.loginPopUpShow = false;
    },
    logclick(v) {
      this.loginPopUpShow = v;
      this.rsgPopUpShow = false;
    },
    meanItemOnClick(item) {
      if (item.path) {
        if (
          !this.user.id &&
          (item.path == "/shareReward" ||
            item.path == "/BagRecharge" ||
            item.path == "/About")
        ) {
          this.$message({
            message: "还未登录，请登录后操作",
            type: "error",
          });
          return false;
        }
        this.$router.push(item.path);
        this.leftNavShow = false;
      }
      if (item.id < 9) {
        if (this.activeId === item.id) {
          this.activeId = "";
        } else {
          this.activeId = item.id;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.fade-enter-active {
  transition: all 0.4s ease;
}

.fade-leave-active {
  transition: all 0.4s ease;
}

.fade-enter {
  transform: translateX(-2rem);
}

.fade-leave-to {
  transform: translateX(-2rem);
}

.comp-m-header-m {
  position: relative;
  width: 3.75rem;
  height: 0.44rem;
  padding: 0rem 0.18rem;
  background: #070d20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  .center-logo {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left-box {
    width: 2rem;
    height: 100vh;
    background: url(../../assets/images/mobile/m-home-bg1.png) no-repeat center;
    background-size: 100% 100%;
    padding-top: 0.26rem;
    padding-left: 0.4rem;
    position: relative;

    .close-btn {
      position: absolute;
      top: 0.13rem;
      right: -0.34rem;
    }

    .left-box-item {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: #a8a8a8;
      font-size: 0.18rem;
      margin-bottom: 0.26rem;

      .left-img {
        width: 0.2rem;
        height: 0.2rem;
        margin-right: 0.18rem;
      }

      .child-box {
        width: 100%;
        padding-left: 0.2rem;

        .left-box-item-child {
          display: flex;
          align-items: center;
          font-size: 0.14rem;
          margin-top: 0.18rem;
          color: #a8a8a8;

          .child-left-img {
            width: 0.2rem;
            height: 0.2rem;
            margin-right: 0.1rem;
          }

          .child-right-text {
          }
        }
      }
    }

    .active-item {
      color: #e9431b;
    }
  }

  .right-icon {
    .beanbox {
      height: 100%;
      padding-right: 0.06rem;

      .money {
        justify-content: flex-start;
      }
      .price2 {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: rgb(236, 177, 5);
        font-weight: 600;
        font-size: 0.12rem;
        img {
          width: 0.12rem;
          margin-right: 0.03rem;
        }
      }
    }

    .login-btn {
      color: #fff;
      font-size: 0.12rem;
    }
  }

  .login-popup {
    width: 3.39rem;
    min-height: 4.11rem;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .reg-popup {
    width: 3.57rem;
    min-height: 4.11rem;
    position: fixed;
    left: 0.03rem;
    top: 50%;
    transform: translate(0, -50%);
  }
}
</style>
